import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import Select from 'react-select'
import { isMobile } from 'react-device-detect'
import { postData } from '../utils/utils'
import CalendlyWidget from './Calendly'

const ContactForm = ({ pro, valuationId }) => {
  moment.locale('fr')

  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [date, setDate] = useState(null)
  const [hour, setHour] = useState(null)
  const [email, setEmail] = useState(null)
  const [newPhone, setNewPhone] = useState('')
  const [dateFocused, setDateFocused] = useState(false)
  const [validated, setValidated] = useState(false)
  const [noError, setNoError] = useState(false)

  const returnYears = () => {
    const years = []
    for (let i = moment().year() - 100; i <= moment().year() + 20; i++) {
      years.push(<option value={i} key={i}>{i}</option>)
    }
    return years
  }

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <select
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
              <option value={value} key={value}>{capitalizeFirstLetter(label)}</option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {returnYears()}
        </select>
      </div>
    </div>
  )

  const sendEmail = () => {
    if (ErrorScheme()) {
      let message = {
        date,
        hour: hour.label,
        phone: newPhone,
        firstName,
        lastName,
        companyName,
        email
      }
      if (!pro) {
        message.valuationId = valuationId
      }
      postData('/api/v1/contact_messages/send', {
        message
      })
        .then((data) => {
          console.log(data)
        })
    }
  }

  const ErrorScheme = () => {
    if (pro) {
      return ![date, hour, newPhone, email, firstName, lastName].some(r => [null, ''].indexOf(r) >= 0)
    }

    return ![date, hour, newPhone].some(r => [null, ''].indexOf(r) >= 0)
  }

  const handleSubmit = () => {
    setValidated(true)

    if (ErrorScheme()) {
      setNoError(true)
      sendEmail()
    } else {
      setNoError(false)
    }
  }

  useEffect(() => {
    if (ErrorScheme()) {
      setNoError(true)
    } else {
      setNoError(false)
    }
  }, [validated])

  return (
    <div>
      {
        validated && noError ? (
          <ContactStyle>
            <div className="contact-row">
              <p><b>RDV pris le <strong>{date.format('L')}</strong> à <strong>{hour.label}</strong> sur votre numéro <strong>{newPhone}</strong>.</b></p>
            </div>
          </ContactStyle>
        ) : (
          <ContactStyle>
            <CalendlyWidget />
            {/* { pro && (
              <div className="contact-row contact-row--mobile-column">
                <input
                  name="fname"
                  aria-label="fname"
                  type="text"
                  onChange={e => setFirstName(e.target.value)}
                  value={firstName}
                  placeholder="Prénom"
                  className="input-email"
                />
                <input
                  name="lname"
                  aria-label="lname"
                  type="text"
                  onChange={e => setLastName(e.target.value)}
                  value={lastName}
                  placeholder="Nom"
                  className="input-email"
                />
              </div>
            )}
            { pro && (
              <div className="contact-row contact-row--mobile-column">
                <input
                  name="email"
                  aria-label="email"
                  type="text"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  placeholder="Email"
                  className="input-email"
                />
                <input
                  name="organization"
                  aria-label="organization"
                  type="text"
                  onChange={e => setCompanyName(e.target.value)}
                  value={companyName}
                  placeholder="Entreprise"
                  className="input-email"
                />
              </div>
            )}
            <div className="contact-row">
              <p><b>Je souhaite prendre un <strong>rendez-vous téléphonique</strong> avec City&You</b></p>
            </div>
            <div className="contact-row">
              <p><b>le</b></p>
              <SingleDatePicker
                id="date-picker-2"
                date={date}
                onDateChange={date => setDate(moment(date))}
                focused={dateFocused}
                onFocusChange={({ focused }) => setDateFocused(focused)}
                firstDayOfWeek={1}
                numberOfMonths={1}
                placeholder={moment().format('L')}
                hideKeyboardShortcutsPanel
                isOutsideRange={(day) => moment().diff(day) > 2 || [5, 6].includes((moment(day).weekday())) }
                displayFormat={() => 'DD/MM/YYYY'}
                renderMonthElement={renderMonthElement}
              />
              <p><b>à</b></p>
              <Select
                value={hour || null}
                onChange={(e) => setHour(e)}
                options={options.hours}
                isSearchable={false}
                placeholder="Choisir"
                className="contact-select"
                styles={isMobile ? selectStyleMobile : selectStyle}
              />
            </div>
            <div className="contact-row">
              <p><b>depuis mon numéro</b></p>
              <input
                name="phone"
                aria-label="phone"
                type="tel"
                onChange={e => setNewPhone(e.target.value)}
                value={getPhoneValue(newPhone)}
                placeholder="0687654321"
                className="input-email"
              />
            </div>
            <div className="contact-row">
              <button onClick={() => handleSubmit()} id="pro-form-submit">Valider</button>
            </div> */}
          </ContactStyle>
        )
      }
    </div>

  )
}

const ContactStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;

  .contact-row {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    p {
      margin: 0 20px 0 0;
      font-size: 20px;
      font-weight: bold;

      strong {
        color: ${props => props.theme.candySecondary};
      }
    }

    .contact-nav {
      padding: 0;

      button:hover {
        background-color: transparent;
        border-color: ${props => props.theme.candyPrimary};
        color: ${props => props.theme.candyPrimary};
      }
    }
  }

  input[name="fname"], input[name="email"] {
    margin-right: 24px;
  }

  .input-email {
    &:focus {
      outline: none;
    }

    height: 50px;
    width: 180px;
    font-size: 18px;
    border: 1px solid ${props => props.theme.candyPrimary};
    border-radius: 28px;
    padding-left: 24px;
    color: ${props => props.theme.candyPrimary};
    margin: 0;

    &::placeholder {
      font-style: italic;
      color: #868898;
    }

    &::placeholder {
      color: #868898;
    }

    @include mq($from: mobile, $until: wide) {
      margin-left: 0px;
      margin-top: 0px;
    }
    @include mq($from: desktop) {
      margin: 0 0 0 26px;
    }
    @include mq($from: mobile, $until: tablet) {
      font-size: 16px
    }

    .suggestion-item {
      color: red !important;
    }
  }

  .SingleDatePicker {
    height: 100%;
    margin-right: 12px;

    select {
      color: ${(props) => props.theme.candyPrimary};
      background: none;
      border: none;
      outline: none;
      font-family: 'Gilroy';
      font-size: 18px;
      cursor: pointer;

      option:hover {
        background: red;
        box-shadow: 0 0 10px 100px ${(props) => props.theme.candyPrimary} inset;
      }
    }

    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
      background: ${props => props.theme.candyPrimary}
    }

    .SingleDatePickerInput__withBorder {
      border-radius: 28px;
      border: 1px solid ${props => props.theme.candyPrimary};
      height: 50px;
      margin-bottom: 40px;
    }

    .DateInput {
      width: 250px;
      height: 100%;
      background: transparent;
      display: flex;
      align-items: center;

      input {
        margin: 0;
        background: transparent;
        padding-left: 16px;
      }
    }

    .DateInput_input {
      padding: 2px;
      font-weight: normal;
      color: ${props => props.theme.candyPrimary} !important;
    }

    .DateInput_input__focused {
      border: none;
    }

    .DayPickerNavigation_button__horizontalDefault {
      top: 24px;
    }

    .DayPicker_weekHeader_li {
      display: inline-block;
    }
  }

  .contact-select > div {
    margin-bottom: 0;
    border-color: ${props => props.theme.candyPrimary};
    color: ${props => props.theme.candyPrimary};
  }

  #pro-form-submit {
    border: 1px solid #31325f;
    color: white;
    border-radius: 20px;
    width: 130px;
    height: 45px;
    background-color: #31325f;
    font-size: 16px;
    font-weight: bold;
  }
`

export const selectStyle = {
  control: base => ({
    ...base,
    zIndex: 1,
    fontFamily: 'Gilroy',
    border: '1px solid #dbe0e4',
    borderRadius: '27px',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '282px',
    height: '53px',
    width: '282px !important',
    marginBottom: '40px',
    backgroundColor: 'white',
    '&:hover': {
      borderColor: '#31325f',
      boxShadow: 'none !important'
    },
    '&:active': {
      borderColor: '#31325f'
    }
  }),
  placeholder: base => ({
    ...base,
    height: '24px',
    opacity: 0.8,
    fontFamily: 'Gilroy',
    fontSize: '18px',
    fontStyle: 'italic',
    letterSpacing: '-0.5px',
    textAlign: 'left',
    color: '#868898',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  singleValue: base => ({
    ...base,
    height: '24px',
    width: '100%',
    fontFamily: 'Gilroy',
    fontSize: '18px',
    fontStyle: 'normal',
    letterSpacing: '-0.5px',
    textAlign: 'left',
    color: '#000',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  indicatorsContainer: base => ({
    ...base,
    svg: {
      fill: '#373737'
    }
  }),
  menu: base => ({
    ...base,
    width: '352px'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      fontFamily: 'Gilroy',
      color: '#31325f',
      fontWeight: isSelected ? 'bold' : 'normal',
      fontSize: '16px',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      width: '352px !important',
      ':active': {
        ...styles[':active'],
        backgroundColor: 'white'
      },
      ':hover': {
        ...styles[':active'],
        backgroundColor: '#31325f',
        fontWeight: 'bold',
        color: 'white'
      }
    }
  }
}

export const selectStyleMobile = {
  control: base => ({
    ...base,
    zIndex: 1,
    fontFamily: 'Gilroy',
    border: '1px solid #dbe0e4',
    borderRadius: '27px',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    height: '53px',
    width: '100% !important',
    marginBottom: '20px',
    backgroundColor: 'white',
    '&:hover': {
      borderColor: '#31325f',
      boxShadow: 'none !important'
    },
    '&:active': {
      borderColor: '#31325f'
    }
  }),
  placeholder: base => ({
    ...base,
    height: '24px',
    opacity: 0.8,
    fontFamily: 'Gilroy',
    fontSize: '14px !important',
    fontStyle: 'italic',
    letterSpacing: '-0.5px',
    textAlign: 'left',
    color: '#868898',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  singleValue: base => ({
    ...base,
    height: '24px',
    width: '100%',
    fontFamily: 'Gilroy',
    fontSize: '14px',
    fontStyle: 'normal',
    letterSpacing: '-0.5px',
    textAlign: 'left',
    color: '#000',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  indicatorsContainer: base => ({
    ...base,
    svg: {
      fill: '#373737'
    }
  }),
  menu: base => ({
    ...base,
    width: '100% !important'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      fontFamily: 'Gilroy',
      color: '#31325f',
      fontWeight: isSelected ? 'bold' : 'normal',
      fontSize: '14px !important',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      width: '100% !important',
      ':active': {
        ...styles[':active'],
        backgroundColor: 'white'
      },
      ':hover': {
        ...styles[':active'],
        backgroundColor: '#31325f',
        fontWeight: 'bold',
        color: 'white'
      }
    }
  }
}

const options = {
  hours: [
    {
      value: 3,
      label: "10h00"
    },
    {
      value: 4,
      label: "10h30"
    },
    {
      value: 5,
      label: "11h00"
    },
    {
      value: 6,
      label: "11h30"
    },
    {
      value: 7,
      label: "12h00"
    },
    {
      value: 8,
      label: "12h30"
    },
    {
      value: 9,
      label: "13h00"
    },
    {
      value: 10,
      label: "13h30"
    },
    {
      value: 11,
      label: "14h00"
    },
    {
      value: 12,
      label: "14h30"
    },
    {
      value: 13,
      label: "15h00"
    },
    {
      value: 14,
      label: "15h30"
    },
    {
      value: 15,
      label: "16h00"
    },
    {
      value: 16,
      label: "16h30"
    },
    {
      value: 17,
      label: "17h00"
    },
    {
      value: 18,
      label: "17h30"
    },
    {
      value: 19,
      label: "18h00"
    },
    {
      value: 20,
      label: "18h30"
    },
  ]
}

export const getPhoneValue = phone => (typeof phone === 'object' ? phone.formatInternational() : phone)

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default ContactForm
