import React from "react";
import { Header, Footer, ReportWrapper, HeaderTertiary } from "./Shared";
import CitySummary from "./CitySummary";
import GeneralSummary from "./GeneralSummary";
import SocioEconomicData from "./SocioEconomicData";
import MarketOverview from "./tertiary/MarketOverview";
import OfficesNear from "./tertiary/OfficesNear";

const TertiaryReport = ({
	city,
	depInseeData,
	inseeData,
	mapboxToken,
	mapTypeLight,
	plot,
}) => {
	const date = new Date();
	const hasDistricts = ["Paris", "Lyon", "Marseille"].some((name) =>
		city.name.startsWith(name),
	);
	const nivGeo = hasDistricts ? "l'arrondissement" : "la ville";
	const localTypes = [
		{ key: "commercial_locals", title: "Commerce" },
		{ key: "office_locals", title: "Bureau" },
		{ key: "activity_locals", title: "Activité" },
	];

	const study_type = plot?.params?.buffer_type;

	return (
		<ReportWrapper>
			<div className="pdf-content">
				<Header address={plot.postal_address} title="Marché tertiaire" />
				<GeneralSummary
					city={city}
					mapboxToken={mapboxToken}
					mapType={mapTypeLight}
					plot={plot}
					report="tertiaryReport"
				/>
				<Footer date={date} />
			</div>

			<div className="pdf-content">
				<Header
					address={plot.postal_address}
					title={`Synthèse générale sur ${nivGeo}`}
				/>
				<CitySummary
					city={city}
					nivGeo={nivGeo}
					inseeData={inseeData}
					mapboxToken={mapboxToken}
					mapTypeLight={mapTypeLight}
					plot={plot}
					report="tertiaryReport"
				/>
				<Footer date={date} />
			</div>

			<div className="pdf-content">
				<Header
					address={plot.postal_address}
					title="Données socio-économiques*"
				/>
				<SocioEconomicData
					depInseeData={depInseeData}
					inseeData={inseeData}
					nivGeo={nivGeo}
				/>
				<Footer date={date} />
			</div>

			{localTypes.map(({ key, title }) => {
				if (
					plot?.params?.office_type === "all_locals" ||
					plot?.params?.office_type === key
				) {
					return (
						<React.Fragment key={key}>
							<div className="pdf-content">
								<HeaderTertiary
									address={plot.postal_address}
									title={title}
									theme="Synthèse du marché"
									studyType={study_type}
									studyArea={true}
									buffer={plot?.params?.buffers?.[key]}
								/>
								<MarketOverview
									city={city}
									plot={plot}
									type={key}
									localType={title}
									studyType={study_type}
									buffer={plot?.params?.buffers?.[key]}
								/>
								<Footer date={date} />
							</div>
							<div className="pdf-content">
								<HeaderTertiary
									address={plot.postal_address}
									title={title}
									theme="Annonces à la vente"
									studyType={study_type}
									studyArea={false}
								/>
								<OfficesNear
									mapboxToken={mapboxToken}
									mapTypeLight={mapTypeLight}
									offices={plot?.nearest_offices?.[key]?.sale}
									city={city}
									plot={plot}
									type={key}
									studyType={study_type}
									buffer={plot?.params?.buffers?.[key]}
								/>
								<Footer date={date} />
							</div>
							<div className="pdf-content">
								<HeaderTertiary
									address={plot.postal_address}
									title={title}
									theme="Annonces à la location"
									studyType={study_type}
									studyArea={false}
								/>
								<OfficesNear
									mapboxToken={mapboxToken}
									mapTypeLight={mapTypeLight}
									offices={plot?.nearest_offices?.[key]?.rent}
									city={city}
									plot={plot}
									type={key}
									location
									studyType={study_type}
									buffer={plot?.params?.buffers?.[key]}
								/>
								<Footer date={date} />
							</div>
						</React.Fragment>
					);
				}
				return null;
			})}
		</ReportWrapper>
	);
};

export default TertiaryReport;
