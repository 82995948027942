import React from "react";
import styled from "styled-components";
import {
	BaseTable,
	camelize,
	lineBreak,
	Stickers,
	Rating,
	cityWithoutDVF,
	ErrorMarker,
	Asterich,
} from "./Shared";

const GeneralSummary = ({
	city,
	mapboxToken,
	mapType,
	multiPlots,
	plot,
	report,
}) => {
	const baseUrl = `https://api.mapbox.com/styles/v1/${mapType}/static`;
	const zoom = 16;
	const width = 680;
	const height = 520;
	const location = `${plot.coordinates.lng},${plot.coordinates.lat},${zoom}`;
	const geometryToGeojson = {
		type: "Feature",
		geometry: plot.geometry_to_geojson,
		properties: {
			stroke: "#d95d57",
			"stroke-width": 2,
			"fill-opacity": 0,
		},
	};
	const geojsonString = JSON.stringify(geometryToGeojson);
	const encodedGeoJSON = `geojson(${encodeURIComponent(geojsonString)})`;
	const mapboxStaticImageUrl = `${baseUrl}/${encodedGeoJSON}/${location}/${width}x${height}@2x?access_token=${mapboxToken}`;
	const formatMarketType = (marketType) => {
		switch (marketType) {
			case "all_locals":
				return "Tous types";
			case "commercial_locals":
				return "Locaux commerciaux";
			case "office_locals":
				return "Bureaux";
			default:
				return "Tout type";
		}
	};

	const maxBuffer = () => {
		if (plot?.params?.buffers) {
			if (plot?.params?.buffer_type === "city") {
				return city.name;
			}
			if (plot?.params?.buffer_type === "auto") {
				return "Automatique";
			}
			return Math.max(...Object.values(plot?.params?.buffers)) / 1000;
		}
	};

	return (
		<div className="pdf-content-inside">
			<GeneralSummaryMap url={mapboxStaticImageUrl} className="mb-12" />
			<BaseTable className="col-2 location mb-12">
				<tbody>
					<tr>
						<td>{plot.address.replace(/, \d+.*/, "")}</td>
					</tr>
					<tr>
						<td className="bold">
							{city.postal_code} {city.name}
						</td>
					</tr>
				</tbody>
			</BaseTable>

			{report === "marketReport" && (
				<BaseTable className="green-background width-240 mb-12">
					<thead>
						<tr>
							<th colSpan="2">Prix de vente recommandé</th>
							{cityWithoutDVF(city.code) && (
								<th colSpan="2">
									<div className="d-flex justify-end w-full mb-5">
										{ErrorMarker}
									</div>
								</th>
							)}
						</tr>
					</thead>
					{cityWithoutDVF(city.code) ? (
						<tbody>
							<tr>
								<td className="pt-5" colSpan="4">
									<div className="">
										En l'absence des données de marché complètes sur le secteur,
										<br />
										nous ne sommes pas en mesure de vous délivrer d'estimation
										de prix.
									</div>
								</td>
							</tr>
						</tbody>
					) : (
						<tbody>
							<tr>
								<td className="pt-5">
									<div className="d-flex flex-column">
										Appartement neuf (TVA 20%) :
										<AsterichNoMargin>
											Estimation basée sur la ventilation typologique retenue
										</AsterichNoMargin>
									</div>
								</td>
								<td className="d-flex gap-4 pt-5">
									<div className="w-100">
										{Math.round(
											plot.city_price_new_by_typo.moy.square_meter_price,
										).toLocaleString("fr-FR")}
										<span> €/m² shab pki</span>
									</div>
									<Rating index={plot.city_price_new.confidence_index?.test} />
								</td>
							</tr>
							<tr className="empty" />
							<tr>
								<td>Appartement dans l’ancien :</td>
								<td className="d-flex gap-4">
									<div className="w-100">
										{Math.round(
											plot.price_old_apartment.adjusted_price_area?.test,
										).toLocaleString("fr-FR")}
										<span> €/m²</span>
									</div>
									<Rating
										index={plot.price_old_apartment.confidence_index?.test}
									/>
								</td>
							</tr>
							<tr>
								<td>Maison dans l'ancien :</td>
								<td className="d-flex gap-4">
									<div className="w-100">
										{Math.round(
											plot.price_old_house.adjusted_price_area?.test,
										).toLocaleString("fr-FR")}
										<span> €/m²</span>
									</div>
									<Rating index={plot.price_old_house.confidence_index?.test} />
								</td>
							</tr>
						</tbody>
					)}
				</BaseTable>
			)}

			{report === "marketReport" ||
				(report === "landReport" && (
					<>
						<div className="page-2-col">
							<div>
								{report === "landReport" && (
									<BaseTable className="col-2 mb-12">
										<tbody>
											<tr>
												<td>Parcelle{multiPlots ? "s" : ""} :</td>
												<td>
													{lineBreak(plot.land_registry_id_short.join(", "))}
												</td>
											</tr>
											<tr>
												<td>
													{multiPlots
														? "Surface des terrains"
														: "Surface du terrain"}{" "}
													:
												</td>
												<td>
													{plot.area.toLocaleString("fr-FR")}
													<span>
														{" "}
														m<sup>2</sup>
													</span>
												</td>
											</tr>
										</tbody>
									</BaseTable>
								)}

								<BaseTable className="col-2 mb-12">
									<tbody>
										<tr>
											<td>Gare la plus proche :</td>
											<td>
												{plot.station ? camelize(plot.station.name) : "-"}
											</td>
										</tr>
										<tr>
											<td>Distance (vol d'oiseau):</td>
											{plot.station?.distance ? (
												<td>
													{plot.station?.distance}
													<span> m</span>
												</td>
											) : (
												<td>-</td>
											)}
										</tr>
									</tbody>
								</BaseTable>
							</div>
						</div>
						<Stickers reducedVat={plot.reduced_vat} pinel={city.pinel} />
					</>
				))}
			{report === "tertiaryReport" && (
				<>
					<div className="mb-12 page-2-col">
						<BaseTable className="green-background">
							<tbody>
								<tr>
									<td>Gare la plus proche :</td>
									<td>{plot.station ? camelize(plot.station.name) : "-"}</td>
								</tr>
								<tr>
									<td>Distance (vol d'oiseau):</td>
									{plot.station?.distance ? (
										<td>
											{plot.station?.distance}
											<span> m</span>
										</td>
									) : (
										<td>-</td>
									)}
								</tr>
								<tr className="empty" />
								<tr>
									<td>Autoroute la plus proche :</td>
									<td>-</td>
								</tr>
								<tr>
									<td>Distance à l'échangeur :</td>
									<td>-</td>
								</tr>
							</tbody>
						</BaseTable>
					</div>
					<div className="mb-12 page-2-col">
						<BaseTable className="col-2 mb-12">
							<tbody>
								<tr>
									<td>Marché cible :</td>
									<td>{formatMarketType(plot?.params?.office_type)}</td>
								</tr>
								{(plot?.params?.office_type === "all_locals" ||
									plot?.params?.office_type === "commercial_locals") && (
									<tr>
										<td>Surface cible Commerce :</td>
										<td>
											<span className="no-bold">Entre </span>
											{`${plot?.params?.target_area_commerce[0]}`}
											<span className="no-bold"> et </span>
											{`${plot?.params?.target_area_commerce[1]}`}{" "}
											<span>m²</span>
										</td>
									</tr>
								)}
								{(plot?.params?.office_type === "all_locals" ||
									plot?.params?.office_type !== "commercial_locals") && (
									<tr>
										<td>Surface cible Bureau/Activité:</td>
										<td>
											<span className="no-bold">Entre </span>
											{`${plot?.params?.target_area_office[0]}`}
											<span className="no-bold"> et </span>
											{`${plot?.params?.target_area_office[1]}`} <span>m²</span>
										</td>
									</tr>
								)}
								<tr>
									<td>Périmètre retenu :</td>
									<td>
										{maxBuffer()}
										{plot?.params?.buffer_type === "custom" ? (
											<span> km</span>
										) : (
											""
										)}
									</td>
								</tr>
							</tbody>
						</BaseTable>
					</div>
				</>
			)}
		</div>
	);
};

const AsterichNoMargin = styled(Asterich)`
  margin-top: 0;
  font-size: 9px;
`;

const GeneralSummaryMap = styled.div`
  width: 100%;
  height: 450px;
  background-image: url("${(props) => props.url}");
  background-size: cover;
  background-position: center;
`;

export default GeneralSummary;
