import React, { useState } from "react";
import moment from "moment";
import MapboxMap, { Marker } from "react-mapbox-wrapper";
import {
	adjustSuperposition,
	CircleProgram,
	defineBounds,
	SerialDataTable,
	WarningMissingData,
} from "../Shared";

const OfficesNear = ({
	mapboxToken,
	mapTypeLight,
	plot,
	type,
	location = false,
	offices,
	studyType,
	buffer,
}) => {
	moment.locale("fr");
	const [map, setMap] = useState(null);
	let indexTable = 1;
	const localType =
		type === "commercial_locals"
			? "Commerce"
			: type === "activity_locals"
				? "Activité"
				: "Bureau";

	const onMapLoad = (mapInstance) => {
		setMap(mapInstance);
		mapInstance.addSource("city", {
			type: "geojson",
			data: {
				type: "Feature",
				geometry: plot.geometry_to_geojson,
			},
		});
	};

	const OfficeMarker = ({ index, available }) => (
		<div
			className={`marker-index ${available ? "marker-index-office-ongoing" : "marker-index-office-sold"}`}
		>
			{map && index}
		</div>
	);
	const anyOffices = offices.length > 0;
	const coordinates = plot.coordinates;

	const formatOfficesForMarker = () => {
		const markers = [];
		if (!anyOffices) {
			const fakeMarker = {
				lat: plot.coordinates.lat,
				lon: plot.coordinates.lng,
				isPlot: true,
			};
			const fakeMarkerEast = {
				lat: plot.coordinates.lat,
				lon: plot.coordinates.lng + 0.001,
			};
			const fakeMarkerWest = {
				lat: plot.coordinates.lat,
				lon: plot.coordinates.lng - 0.001,
			};

			markers.push(fakeMarker);
			markers.push(fakeMarkerEast);
			markers.push(fakeMarkerWest);
			return markers;
		}

		if (offices.length > 0) {
			offices.map((office, index) => {
				const obj = {
					lat: office.latitude,
					lon: office.longitude,
					indexOffice: index + 1,
					available: office.available,
				};
				markers.push(obj);
			});
		}
		return markers;
	};

	const NoCoverIcon = () => (
		<svg
			width="51"
			height="36"
			viewBox="0 0 51 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>No Cover Icon</title>
			<rect width="51" height="36" rx="4" fill="#E9E9E9" />
			<path
				d="M25.5 23.4803C26.7505 23.4803 27.8105 23.0528 28.6801 22.1978C29.5498 21.3428 29.9846 20.3006 29.9846 19.0711C29.9846 17.8417 29.5498 16.7995 28.6801 15.9445C27.8105 15.0895 26.7505 14.662 25.5 14.662C24.2495 14.662 23.1895 15.0895 22.3199 15.9445C21.4502 16.7995 21.0154 17.8417 21.0154 19.0711C21.0154 20.3006 21.4502 21.3428 22.3199 22.1978C23.1895 23.0528 24.2495 23.4803 25.5 23.4803ZM25.5 21.8735C24.6952 21.8735 24.019 21.6042 23.4714 21.0656C22.9237 20.5272 22.6498 19.8624 22.6498 19.0711C22.6498 18.2799 22.9237 17.6151 23.4714 17.0767C24.019 16.5381 24.6952 16.2688 25.5 16.2688C26.3048 16.2688 26.981 16.5381 27.5286 17.0767C28.0763 17.6151 28.3502 18.2799 28.3502 19.0711C28.3502 19.8624 28.0763 20.5272 27.5286 21.0656C26.981 21.6042 26.3048 21.8735 25.5 21.8735ZM17.1174 27.1069C16.5669 27.1069 16.101 26.9194 15.7196 26.5444C15.3381 26.1694 15.1474 25.7112 15.1474 25.17V12.9723C15.1474 12.4311 15.3381 11.9729 15.7196 11.5979C16.101 11.2229 16.5669 11.0354 17.1174 11.0354H20.4452L22.4613 8.89258H28.5388L30.5548 11.0354H33.8826C34.4331 11.0354 34.899 11.2229 35.2805 11.5979C35.6619 11.9729 35.8526 12.4311 35.8526 12.9723V25.17C35.8526 25.7112 35.6619 26.1694 35.2805 26.5444C34.899 26.9194 34.4331 27.1069 33.8826 27.1069H17.1174ZM17.1174 25.4997H33.8826C33.9805 25.4997 34.0608 25.4688 34.1237 25.407C34.1865 25.3453 34.218 25.2662 34.218 25.17V12.9723C34.218 12.8761 34.1865 12.797 34.1237 12.7353C34.0608 12.6735 33.9805 12.6426 33.8826 12.6426H29.8255L27.8261 10.4997H23.1739L21.1745 12.6426H17.1174C17.0195 12.6426 16.9392 12.6735 16.8763 12.7353C16.8135 12.797 16.7821 12.8761 16.7821 12.9723V25.17C16.7821 25.2662 16.8135 25.3453 16.8763 25.407C16.9392 25.4688 17.0195 25.4997 17.1174 25.4997Z"
				fill="#999999"
			/>
		</svg>
	);

	const formattedMarkers = formatOfficesForMarker();
	const bounds = defineBounds(formattedMarkers);
	const markers = adjustSuperposition(formattedMarkers, bounds);
	markers.push({
		lat: plot.coordinates.lat,
		lon: plot.coordinates.lng,
		isPlot: true,
	});
	const newBounds = defineBounds(markers);

	return (
		<div className="align-start pdf-content-inside">
			<div id="near-programs-map" className="mb-12">
				<MapboxMap
					accessToken={mapboxToken}
					coordinates={plot.coordinates}
					bounds={newBounds}
					fitBoundsOptions={{
						padding: { top: 40, right: 100, bottom: 40, left: 40 },
					}}
					onLoad={(e) => onMapLoad(e)}
					mapboxStyle={`mapbox://styles/${mapTypeLight}`}
					preserveDrawingBuffer
					className="border-radius-3"
				>
					{map &&
						markers.map((marker) => (
							<Marker
								key={`${marker.lat}-${marker.lon}`}
								map={map}
								coordinates={{ lat: marker.lat, lng: marker.lon }}
								anchor="left"
							>
								{marker.isPlot ? (
									// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
									<svg
										width="34"
										height="34"
										viewBox="0 0 34 34"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M14.0005 2.33301C9.48547 2.33301 5.8338 5.98467 5.8338 10.4997C5.8338 16.6247 14.0005 25.6663 14.0005 25.6663C14.0005 25.6663 22.1671 16.6247 22.1671 10.4997C22.1671 5.98467 18.5155 2.33301 14.0005 2.33301Z"
											fill="#D95D57"
										/>
										<ellipse
											cx="14.0005"
											cy="10.4997"
											rx="2.91667"
											ry="2.91667"
											fill="white"
										/>
									</svg>
								) : (
									<OfficeMarker
										index={marker.indexOffice}
										available={marker.available}
									/>
								)}
							</Marker>
						))}
				</MapboxMap>
			</div>
			<div className="d-flex gap-12 programs-legend mb-12">
				<div>
					<span className="tertiary-bullet" />
					Annonce disponible
				</div>
				<div>
					<span className="tertiary-bullet-empty" />
					Annonce indisponible
				</div>
			</div>
			{anyOffices ? (
				<SerialDataTable>
					<tbody>
						{offices.map((office) => (
							<tr
								key={office.id}
								className={`height-40px ${location ? "red-background" : "blue-background"}`}
							>
								<td className="w-7">
									<div className="d-flex justify-content-center w-full">
										<CircleProgram
											className={`${office.available ? "office-ongoing" : "office-finished"}`}
										>
											{indexTable++}
										</CircleProgram>
									</div>
								</td>
								<td className="w-10">
									<div className="office-cover">
										{office.cover_url ? (
											<img src={office.cover_url} alt="office-cover" />
										) : (
											<NoCoverIcon />
										)}
									</div>
								</td>
								<td>
									<div className="d-flex flex-column">
										<span className="bold">{`${office.type || localType} - ${office?.area} m²`}</span>
										<span>
											{office.available ? "Disponible" : "Indisponible"}
										</span>
									</div>
								</td>
								<td className="td-container">
									<div className="d-flex flex-column">
										<span className="bold">
											{office?.price
												? `${office?.price?.toLocaleString("fr-FR")} € - ${office?.sqm_price?.toLocaleString("fr-FR")} €${location ? "/m²/an H.C. H.T." : "/m² H.H"}`
												: "-"}
										</span>
										<span>{`Mis en ligne : ${moment(office?.site_publication_date).format("MMMM YYYY")}`}</span>
									</div>
								</td>
								<td className="td-container">
									<div className="d-flex flex-column">
										<span className="bold">{office?.city}</span>
										<span>{`Distance : ${(Math.round(office?.distance / 10) * 10)?.toLocaleString("fr-FR")} m`}</span>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</SerialDataTable>
			) : (
				<WarningMissingData>
					<p>
						<b>Nous n'avons pas trouvé de locaux de type {localType}</b>
						<br />
						{studyType === "city"
							? `sur la commune de ${buffer}`
							: `dans un rayon de ${buffer} km`}
					</p>
				</WarningMissingData>
			)}
		</div>
	);
};

export default OfficesNear;
