const font = { size: 10, family: "Gilroy" };
const colorFont = { font, color: "#999999" };

// Fonction pour interpoler les valeurs manquantes en fonction des valeurs précédentes et suivantes, pour rendre le graphique linéaire
const interpolateNullValues = (data) => {
	const keys = Object.keys(data);
	const values = Object.values(data);

	const firstNonNullIndex = values.findIndex((value) => value !== null);
	const lastNonNullIndex =
		values.length -
		1 -
		[...values].reverse().findIndex((value) => value !== null);

	for (let i = firstNonNullIndex; i <= lastNonNullIndex; i++) {
		if (values[i] === null) {
			let prevValue = null;
			let nextValue = null;
			let prevIndex = i - 1;
			let nextIndex = i + 1;

			while (prevIndex >= firstNonNullIndex && prevValue === null) {
				prevValue = values[prevIndex];
				prevIndex--;
			}

			while (nextIndex <= lastNonNullIndex && nextValue === null) {
				nextValue = values[nextIndex];
				nextIndex++;
			}

			if (prevValue !== null && nextValue !== null) {
				values[i] = (prevValue + nextValue) / 2;
			} else if (prevValue !== null) {
				values[i] = prevValue;
			} else if (nextValue !== null) {
				values[i] = nextValue;
			}
		}
	}

	const interpolatedData = {};
	keys.forEach((key, index) => {
		interpolatedData[key] = values[index];
	});

	return interpolatedData;
};

const chartTitle = (title) => ({
	display: true,
	text: title,
	padding: { top: 10, bottom: 0 },
	color: "#31325F",
	align: "start",
	font: {
		family: "Gilroy, sans-serif",
		size: 14,
		weight: 600,
	},
});

const chartSubTitle = {
	display: true,
	text: "Au cours des 12 derniers mois",
	padding: { top: 0, bottom: 30 },
	align: "start",
	color: "#31325F",
	font: {
		family: "Gilroy, sans-serif",
		size: 12,
		weight: 400,
	},
};

const baseFormat = (datas) => ({
	type: "bar",
	data: {
		labels: datas.map((data) => data.label),
		datasets: [
			{
				label: "Ville",
				data: datas.map((data) => data.data1),
				backgroundColor: "#ffeb78",
				borderRadius: 10,
			},
			{
				label: "Dépt.",
				data: datas.map((data) => data.data2),
				backgroundColor: "#639A5F",
				borderRadius: 10,
			},
		],
	},
	options: {
		responsive: true,
		devicePixelRatio: 3,
		plugins: {
			legend: {
				display: true,
				position: "bottom",
				labels: {
					boxWidth: 4,
					boxHeight: 4,
					usePointStyle: true,
					filter: (item, chart) => item.text !== "hidden",
					font,
					color: "#999999",
				},
			},
			datalabels: {
				display: false,
			},
		},
		scales: {
			y: {
				grid: { drawTicks: false },
				border: { dash: [5, 5], color: "transparent" },
				ticks: {
					font,
					color: "#999999",
					min: 0,
					stepSize: 0.1,
					callback: (value) => `${(value * 100).toFixed(0)}%`,
				},
			},
			x: {
				font,
				color: "#999999",
				grid: { display: false },
				border: { color: "transparent" },
			},
		},
		categoryPercentage: 0.2,
		barPercentage: 0.5,
	},
});

const transactionsFormat = (datas) => {
	const newFormat = baseFormat(datas);
	newFormat.data.datasets = [
		{
			label: "hidden",
			data: datas.map((data) => data.data1),
			backgroundColor: "#31325F",
			borderRadius: 10,
			barThickness: 6,
		},
	];
	newFormat.options.plugins.title = chartTitle(
		"Nombre d'appartements vendus par typologie *",
	);
	newFormat.options.plugins.subtitle = chartSubTitle;
	newFormat.options.scales.x.ticks = colorFont;
	newFormat.options.scales.y.ticks.callback = (value) => `${value} biens`;
	newFormat.options.scales.y.ticks.stepSize = 4;
	newFormat.options.plugins.datalabels = {
		align: "end",
		anchor: "end",
		color: "#999999",
		formatter: (value, context) => (value !== 0 ? value : ""),
		font,
	};
	return newFormat;
};

const pricesFormat = (datas) => {
	const newFormat = baseFormat(datas);
	newFormat.type = "scatter";

	const borderRadius = {
		topLeft: 10,
		topRight: 10,
		bottomLeft: 10,
		bottomRight: 10,
	};

	newFormat.data.datasets = [
		{
			type: "bar",
			label: "hidden",
			data: datas.map((data) => ({
				x: data.label,
				y: [data.data2, data.data3],
			})),
			borderRadius,
			barPercentage: 0.3,
			categoryPercentage: 0.3,
			backgroundColor: "rgba(242, 246, 250, 0.8)",
			order: 2,
		},
		{
			type: "scatter",
			label: "hidden",
			data: datas
				.map((data) => ({
					x: data.label,
					y: data.data1,
				}))
				.filter((point) => point.y !== 0),
			backgroundColor: "rgba(49, 50, 95, 1)",
			pointRadius: 4,
			order: 1,
		},
	];
	newFormat.options.plugins.title = chartTitle(
		"Prix/m² moyen par typologie d'appartement *",
	);
	newFormat.options.plugins.subtitle = chartSubTitle;
	newFormat.options.scales.y.ticks.callback = (value) =>
		`${Math.round(value / 1000).toLocaleString("fr-FR")} K€/m²`;
	newFormat.options.scales.y.beginAtZero = false;
	newFormat.options.scales.y.ticks.stepSize = 1000;
	newFormat.options.scales.x.ticks = colorFont;
	newFormat.options.scales.x.type = "category";
	newFormat.options.scales.x.labels = datas.map((data) => data.label);
	newFormat.options.plugins.datalabels = {
		align: "left",
		color: "#999999",
		font,
		formatter: (value, context) =>
			value.y !== 0 && context.dataset.type === "scatter"
				? `${(Math.round(value.y / 100) / 10).toLocaleString("fr-FR")}K`
				: "",
		padding: { left: 10 },
	};
	return newFormat;
};

const evolutionFormat = (datas) => {
	const newFormat = baseFormat(datas);
	newFormat.options.plugins.title = chartTitle("Evolution des prix de vente");
	newFormat.type = "line";
	newFormat.data.datasets[0].backgroundColor = "#31325F";
	newFormat.data.datasets[0].label = "Appartements";
	newFormat.data.datasets[1].backgroundColor = "#A6C3DB";
	newFormat.data.datasets[1].label = "Maisons";
	newFormat.options.plugins.title.padding.bottom = 20;
	newFormat.options.scales.x.ticks = colorFont;
	newFormat.options.scales.y.ticks.stepSize = 1000;
	newFormat.options.scales.y.ticks.padding = 15;
	newFormat.options.scales.y.ticks.callback = (value) =>
		`${Math.round(value / 1000).toLocaleString("fr-FR")} K€/m²`;
	newFormat.options.maintainAspectRatio = false;
	return newFormat;
};

const stocksFormat = (datas, color) => {
	const newFormat = baseFormat(datas);
	const maxValue = Math.round(
		Math.max(...datas.map((data) => data.data1)) * 1.1,
	);
	// biome-ignore lint/performance/noDelete: <explanation>
	delete newFormat.options.scales.y.ticks.callback;
	// biome-ignore lint/performance/noDelete: <explanation>
	delete newFormat.options.categoryPercentage;
	// biome-ignore lint/performance/noDelete: <explanation>
	delete newFormat.options.barPercentage;
	newFormat.data.datasets[0].backgroundColor = color;
	newFormat.data.datasets[0].barThickness = 6;
	newFormat.data.datasets.push({
		data: datas.map(() => maxValue),
		backgroundColor: "#E0E0E0",
		barThickness: 6,
		borderRadius: 10,
	});

	newFormat.options.indexAxis = "y";
	newFormat.options.plugins.legend.display = false;
	newFormat.options.scales.y.grid.display = false;
	newFormat.options.plugins.datalabels = {
		align: "end",
		anchor: "end",
		color,
		formatter: (value, context) => (value !== maxValue ? value : ""),
	};

	newFormat.options.scales.y.ticks.align = "start";
	newFormat.options.scales.y.ticks.crossAlign = "start";
	newFormat.options.scales.y.stacked = true;
	newFormat.options.scales.y.ticks = colorFont;

	newFormat.options.scales.x.ticks = colorFont;
	newFormat.options.scales.x.max = Math.max(datas.map((d) => d.data1)) + 2;
	return newFormat;
};

export const roomsDistribution = (inseeData, depInseeData) => {
	const rawData = ["t1", "t2", "t3", "t4", "t5"].map((type, index) => ({
		id: index + 1,
		label: type === "t5" ? "T5+" : type.toUpperCase(),
		data1: inseeData[type] / inseeData.ens,
		data2: depInseeData[type] / depInseeData.ens,
	}));

	const newFormat = baseFormat(rawData);
	newFormat.options.scales.x.ticks = colorFont;
	return newFormat;
};

export const completionYearDistribution = (inseeData, depInseeData) => {
	const rawData = [
		["b1919", ["Avant", "1919"]],
		["f1919", ["1919", "1945"]],
		["f1946", ["1946", "1970"]],
		["f1971", ["1971", "1991"]],
		["f1991", ["1991", "2005"]],
		["f2006", ["2006", "2017"]],
	].map((type, index) => ({
		id: index,
		label: type[1],
		data1: inseeData[type[0]] / inseeData.ens,
		data2: depInseeData[type[0]] / depInseeData.ens,
	}));

	const newFormat = baseFormat(rawData);
	newFormat.options.scales.x.ticks = colorFont;
	return newFormat;
};

export const ageDistribution = (inseeData, depInseeData) => {
	const rawData = [
		["f00", "0 - 14"],
		["f15", "15 - 29"],
		["f30", "30 - 44"],
		["f45", "45 - 59"],
		["f60", "60 - 74"],
		["f75", "75 et +"],
	].map((type, index) => ({
		id: index,
		label: type[1],
		data1: inseeData[type[0]] / inseeData.ens,
		data2: depInseeData[type[0]] / depInseeData.ens,
	}));

	const newFormat = baseFormat(rawData);
	newFormat.options.scales.x.ticks = colorFont;
	return newFormat;
};

export const tertiarySellsDistribution = (
	type,
	officeData,
	location = false,
) => {
	const areaScales = {
		commercial_locals: ["0-100", "100-200", "200-500", "+500"],
		office_locals: ["0-500", "500-1500", "1500-3000", "+3000"],
		activity_locals: ["0-500", "500-1500", "1500-3000", "+3000"],
	};

	const areaCategories = areaScales[type] || [
		"0-500",
		"500-1500",
		"1500-3000",
		"+3000",
	];
	const rawData = areaCategories.map((area, index) => {
		const total = officeData?.[area]?.total || 0;
		const available = officeData?.[area]?.available || 0;

		return {
			id: index,
			label: `${area} m²`,
			data1: available,
			data2: total,
		};
	});

	const newFormat = baseFormat(rawData);
	newFormat.options.maintainAspectRatio = false;
	newFormat.options.scales.x.ticks = colorFont;
	newFormat.options.plugins.datalabels.display = true;
	newFormat.data.datasets = [
		{
			label: "Disponibles à date",
			data: rawData.map((data) => data.data1),
			backgroundColor: location ? "#D95D57" : "#31325f",
			borderRadius: 5,
		},
		{
			label: "Publiées sur 12 mois",
			data: rawData.map((data) => data.data2),
			backgroundColor: location ? "#FFAAA5" : "#A6C3DB",
			borderRadius: 5,
		},
	];
	newFormat.options.plugins.title = chartTitle(
		`Nbre d’annonce à la ${location ? "location" : "vente"}`,
	);

	const chartSubTitle = {
		display: true,
		text: "Sur le secteur d’étude",
		padding: { top: 0, bottom: 20 },
		align: "start",
		color: "#31325F",
		font: {
			family: "Gilroy, sans-serif",
			size: 12,
			weight: 400,
		},
	};
	newFormat.options.plugins.subtitle = chartSubTitle;
	newFormat.options.scales.y.ticks = {
		callback: (value) => `${Math.round(value)}`,
		stepSize: 10,
		maxTicksLimit: 6,
		color: "#999999",
		font: {
			family: "Gilroy, sans-serif",
			size: 10,
			weight: 400,
		},
	};
	newFormat.options.plugins.datalabels = {
		display: true,
		anchor: "end",
		align: "top",
		color: colorFont.color,
		font: font,
		formatter: (value) => (value > 0 ? value : ""),
	};
	return newFormat;
};

export const tertiaryPriceDistribution = (
	type,
	officeData,
	location = false,
) => {
	const areaScales = {
		commercial_locals: ["0-100", "100-200", "200-500", "+500"],
		office_locals: ["0-500", "500-1500", "1500-3000", "+3000"],
		activity_locals: ["0-500", "500-1500", "1500-3000", "+3000"],
	};

	const areaCategories = areaScales[type] || [
		"0-500",
		"500-1500",
		"1500-3000",
		"+3000",
	];

	const rawData = areaCategories.map((area) => ({
		label: `${area} m²`,
		data1: officeData?.[area]?.moy || 0,
		data2: officeData?.[area]?.min || 0,
		data3: officeData?.[area]?.max || 0,
	}));

	const isEmptyData = rawData.every(
		(data) => data.data1 === 0 && data.data2 === 0 && data.data3 === 0,
	);

	const newFormat = pricesFormat(rawData);
	newFormat.options.maintainAspectRatio = false;
	newFormat.options.scales.y.ticks.stepSize = location ? 100 : 1000;
	newFormat.options.plugins.datalabels.display = true;
	newFormat.data.datasets = [
		{
			type: "bar",
			label: "hidden",
			data: rawData.map((data) => ({
				x: data.label,
				y: [data.data2, data.data3],
			})),
			borderRadius: 10,
			barPercentage: 0.2,
			categoryPercentage: 0.3,
			backgroundColor: location ? "#FCE6E6" : "#F2F6FA",
			order: 2,
		},
		{
			type: "scatter",
			label: "hidden",
			data: rawData
				.map((data) => ({
					x: data.label,
					y: data.data1,
				}))
				.filter((point) => point.y !== 0),
			backgroundColor: location ? "#D95D57" : "#31325f",
			pointRadius: 4,
			order: 1,
			datalabels: {
				align: "left",
				anchor: "end",
				color: "#999999",
				font: font,
				formatter: (value) =>
					location ? `${value} €` : `${(value / 1000).toFixed(1)} K€`,
				padding: { right: 8 },
			},
		},
	];

	newFormat.options.plugins.title = chartTitle(
		`${location ? "Loyer moyen / m² (HC-HT)" : "Prix vente moyen / m² (HH)"}`,
	);

	const chartSubTitle = {
		display: true,
		text: "Sur le secteur d’étude",
		padding: { top: 0, bottom: 20 },
		align: "start",
		color: "#31325F",
		font: {
			family: "Gilroy, sans-serif",
			size: 12,
			weight: 400,
		},
	};

	newFormat.options.scales.y.ticks = {
		callback: (value) =>
			location
				? `${Math.round(value)} €/m²`
				: `${Math.round(value / 1000)} K€/m²`,
		stepSize: 10,
		maxTicksLimit: 6,
		color: "#999999",
		font: {
			family: "Gilroy, sans-serif",
			size: 10,
			weight: 400,
		},
	};
	if (isEmptyData) {
		newFormat.options.scales.y.min = 0;
		newFormat.options.scales.y.max = location ? 30 : 3000;
	}
	newFormat.options.plugins.subtitle = chartSubTitle;

	return newFormat;
};

const transactionsDistributionData = (appartments, key) =>
	Object.keys(appartments).map((k) => ({
		id: 1,
		label: k === "t5" ? "T5+" : k.toUpperCase(),
		data1: appartments[k][key],
		data2: appartments[k].square_meter_price_d1,
		data3: appartments[k].square_meter_price_d9,
	}));

export const sellsDistribution = (appartments) => {
	// biome-ignore lint/performance/noDelete: <explanation>
	delete appartments.moy;
	const newFormat = transactionsFormat(
		transactionsDistributionData(appartments, "nbr"),
	);
	newFormat.options.maintainAspectRatio = false;
	return newFormat;
};

export const priceDistribution = (appartments) => {
	// biome-ignore lint/performance/noDelete: <explanation>
	delete appartments.moy;
	const newFormat = pricesFormat(
		transactionsDistributionData(appartments, "square_meter_price"),
	);
	newFormat.options.maintainAspectRatio = false;
	newFormat.options.scales.y.ticks.stepSize = 1000;
	return newFormat;
};

export const priceEvolution = (appartments, houses, dvfLastYear) => {
	const dataAppartmentsFormatted = interpolateNullValues(appartments);
	const dataHousesFormatted = interpolateNullValues(houses);

	const years = Object.keys(appartments).map((date) => date.split("-")[0]);
	const dvfYear = new Date(dvfLastYear).getFullYear();
	const month = new Date(dvfLastYear).getMonth() + 1;
	const lastYear = years[years.length - 1];
	const lastYearLabel = month >= 6 ? `${lastYear} (juin)` : lastYear;

	const rawData = years.map((year, index) => ({
		id: index,
		label:
			Number.parseInt(year) === Number.parseInt(lastYear) &&
			Number.parseInt(year) === dvfYear
				? lastYearLabel
				: year,
		data1: dataAppartmentsFormatted[`${year}-01-01`],
		data2: dataHousesFormatted[`${year}-01-01`],
	}));

	return evolutionFormat(rawData);
};

export const stocksDistribution = (datas, color) => {
	const rawData = ["t1", "t2", "t3", "t4", "t5"].map((type, index) => ({
		id: index + 1,
		label: type === "t5" ? "T5+" : type.toUpperCase(),
		data1: datas[type].nbr,
	}));
	return stocksFormat(rawData, color);
};

export const reservationsDistribution = (datas, color) => {
	const rawData = ["t1", "t2", "t3", "t4", "t5"].map((type, index) => ({
		id: index + 1,
		label: type === "t5" ? "T5+" : type.toUpperCase(),
		data1: datas[type].nbr,
	}));
	return stocksFormat(rawData, color);
};
