import React from "react";
import styled, { css, ThemeProvider } from "styled-components";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import theme from "../../stylesheets/theme";
import Location from "./images/Location.svg";

export const ReportWrapper = ({ children }) => (
	<div style={{ WebkitPrintColorAdjust: "exact" }}>
		<ThemeProvider theme={theme}>
			<StyledWrapper>{children}</StyledWrapper>
		</ThemeProvider>
	</div>
);

export const percent = (
	nivgeo,
	dataSet,
	value,
	boldClass = "",
	difference = false,
) => {
	if (nivgeo[dataSet][value] && nivgeo[dataSet].ens) {
		return (
			<td className={boldClass}>
				{difference
					? 100 -
						Math.round((nivgeo[dataSet][value] / nivgeo[dataSet].ens) * 100)
					: Math.round((nivgeo[dataSet][value] / nivgeo[dataSet].ens) * 100)}
				<span> %</span>
			</td>
		);
	}
	return <td>-</td>;
};

export const secPercent = (nivgeo, dataSet, value) => {
	if (nivgeo[dataSet][value] && nivgeo[dataSet].ens) {
		return (
			<span>
				({Math.round((nivgeo[dataSet][value] / nivgeo[dataSet].ens) * 100)}%)
			</span>
		);
	}
};

export const Header = ({ title, address }) => (
	<header>
		<h1>{title}</h1>
		<h2>
			<img className="mr-3" src={Location} alt="location" /> {address}
		</h2>
	</header>
);

export const HeaderTertiary = ({
	title,
	address,
	theme,
	studyType,
	studyArea,
	buffer = null,
}) => {
	const formatBuffer = () => {
		if (studyType === "city") {
			return "Commune";
		}
		return `${buffer / 1000} km`;
	};

	return (
		<header>
			<div className="d-flex justify-content-between w-full header-tertiary">
				<div className="">
					<div className="d-flex align-center mb-5">
						<h1>{title}</h1>
						{theme && <div className="theme">{theme}</div>}
					</div>
					<h2>
						<img className="mr-3" src={Location} alt="location" /> {address}
					</h2>
				</div>
				<div
					className={`yellow-background study-area ${studyArea ? "" : "hidden"}`}
				>
					<div className="bold mb-2">Secteur d’étude :</div>
					<div className="font-size-12px bold mb-2">{formatBuffer()}</div>
					<div className="color-light-grey font-size-9px">
						{studyType === "city"
							? "De l’adresse étudiée"
							: "Autour de l’adresse"}
					</div>
				</div>
			</div>
		</header>
	);
};

export const Footer = ({ date }) => (
	<footer>
		<div className="white-border" />
		<div className="footer-box">
			<div className="bold">
				Ce rapport a été généré le {moment(date).format("DD/MM/YYYY")}
			</div>
			<div className="d-flex">
				<div className="comment">
					GABARIT ne garantit pas la parfaite exhaustivité des données mises à
					disposition au sein de ses solutions d’analyse.
					<br />
					GABARIT ne saurait être tenue responsable de toutes pertes découlant
					d’une décision fondée sur une analyse, prévision ou
					<br />
					autre information apparaissant dans le cadre de ses solutions.
				</div>
				{/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
				<svg
					width="23"
					height="32"
					viewBox="0 0 23 32"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9.5104 1C4.81818 1 1 4.96226 1 9.84434L1.023 22.3915L9.28039 31H13.6966H13.7196C15.8587 31 17.8828 30.1274 19.4469 28.5472C21.103 26.8491 22 24.5849 22 22.1321V1H9.5104ZM2.15005 11.4953H9.92442V20.5283H2.17306L2.15005 11.4953ZM17.5608 20.5283C15.2837 20.1981 13.5356 18.3349 13.5356 16.1179V16.0236C13.5356 13.5472 15.6747 11.5425 18.2968 11.5425H20.7579V20.5047H17.5608V20.5283ZM20.8039 10.2925H11.0975V2.20283H20.8039V10.2925ZM9.5104 2.20283H9.94743V10.2925H2.15005V9.84434C2.15005 5.62264 5.43921 2.20283 9.5104 2.20283ZM2.17306 21.8962V21.7075H9.94743V29.8208H9.76342L2.17306 21.8962ZM18.5958 27.7217C17.2618 29.0896 15.5367 29.8208 13.6966 29.8208C13.6966 29.8208 13.6966 29.8208 13.6736 29.8208H11.0975V21.7075H17.4918C17.7678 21.7311 18.0208 21.7547 18.2968 21.7547L20.8039 21.7075V22.1321C20.8039 24.2783 20.0219 26.2594 18.5958 27.7217Z"
						fill="#31325F"
						stroke="#31325F"
						strokeWidth="0.4"
					/>
				</svg>
			</div>
		</div>
	</footer>
);

export const Stickers = ({ reducedVat, pinel }) => (
	<div className="stickers mb-12">
		<div className="sticker">{reducedVat ? "TVA 5.5" : <del>TVA 5.5</del>}</div>
		<div className="sticker">Zone {pinel}</div>
	</div>
);

export const singleHead = (title, colspan = "2", grey = false) => (
	<thead>
		<tr>
			<th colSpan={colspan} className={grey ? "color-light-grey" : ""}>
				{title}
			</th>
		</tr>
	</thead>
);

export const singleHead2 = (title, colspan = "2") => (
	<thead>
		<tr>
			<th colSpan={colspan} className="font-size-14px">
				{title}
			</th>
		</tr>
	</thead>
);

export const doubleHead = (title, subTitle, colspan = "2") => (
	<thead className="title-2-lines">
		<tr>
			<th colSpan={colspan}>
				{title}
				<br />
				<span>{subTitle}</span>
			</th>
		</tr>
	</thead>
);

export const subHead = (
	title1 = "Ville",
	title2 = "Dépt.",
	title3 = null,
	title4 = null,
) => (
	<tr>
		<td />
		<td className="sub-head">{title1}</td>
		<td className="sub-head">{title2}</td>
		{title3 && <td className="sub-head">{title3}</td>}
		{title4 && <td className="sub-head">{title4}</td>}
	</tr>
);

export const col5Table = (data, rating = null, weight = false) => (
	<tbody className="col-5">
		{subHead(weight ? "%" : "Nbre", "SHAB", "€/m²", "TTC")}
		{["t1", "t2", "t3", "t4", "t5"].map((type) => (
			<tr key={type}>
				<td className="no-bold">
					{type === "t5" ? "T5 et +" : type.toUpperCase()}
				</td>
				<td className="no-bold">
					{data[type]?.nbr > 0 ? data[type]?.nbr : "-"}
					{weight && data[type]?.nbr > 0 ? <span> %</span> : ""}
				</td>
				<td className="no-bold">
					{data[type]?.living_area > 0 ? data[type]?.living_area : "-"}
					{data[type]?.living_area > 0 && <span> m²</span>}
				</td>
				<td className="no-bold">
					{data[type]?.nbr > 0 && data[type]?.square_meter_price > 0
						? data[type]?.square_meter_price?.toLocaleString("fr-FR")
						: "-"}
				</td>
				<td className="no-bold">
					{data[type]?.price > 0 ? Math.round(data[type]?.price / 1000) : "-"}
					{data[type]?.price > 0 && <span> K€</span>}
				</td>
			</tr>
		))}
		{
			<tr key="moy">
				<td className="bold">Ensemble</td>
				<td>
					{data.moy?.nbr || "-"}
					{weight ? <span> %</span> : ""}
				</td>
				<td>
					{data.moy?.living_area > 0 ? data.moy?.living_area : "-"}
					{data.moy?.living_area > 0 && <span> m²</span>}
				</td>
				<td>
					{data.moy?.square_meter_price?.toLocaleString("fr-FR") !== "0"
						? data.moy?.square_meter_price?.toLocaleString("fr-FR")
						: "-"}
				</td>
				<td>
					{data.moy?.price > 0 ? Math.round(data.moy?.price / 1000) : "-"}
					{data.moy?.price > 0 && <span> K€</span>}
				</td>
				{rating && data.moy?.price && (
					<td>
						<Rating index={rating} />
					</td>
				)}
			</tr>
		}
	</tbody>
);

export const col5TableProgram = (data, color = "light-blue") => (
	<tbody className="col-5 detailed-lots-table">
		{subHead("Nbre", "SHAB", "€ pki", "€/m² pki")}
		{["t1", "t2", "t3", "t4", "t5"].map((type) => (
			<tr key={type}>
				<td className="w-20 no-bold">
					{type === "t5" ? "T5 et +" : type.toUpperCase()}
				</td>
				<td className="w-20 no-bold">
					{data[type]?.nbr > 0 ? data[type]?.nbr : "-"}
				</td>
				<td className="w-20 no-bold">
					{data[type]?.living_area > 0 ? data[type]?.living_area : "-"}
				</td>
				<td className="w-20 no-bold">
					{data[type]?.price > 0
						? data[type]?.price?.toLocaleString("fr-FR")
						: "-"}
				</td>
				<td className="w-20 no-bold">
					{data[type]?.square_meter_price > 0
						? data[type]?.square_meter_price?.toLocaleString("fr-FR")
						: "-"}
				</td>
			</tr>
		))}
		{
			<tr>
				<td className={`bold border-top-${color} pt-5`}>Ensemble</td>
				<td className={`bold pt-5 border-top-${color}`}>
					{data.moy?.nbr > 0 ? data.moy?.nbr : "-"}
				</td>
				<td className={`bold pt-5 border-top-${color}`}>
					{data.moy?.living_area > 0 ? data.moy?.living_area : "-"}
				</td>
				<td className={`bold pt-5 border-top-${color}`}>
					{data.moy?.price > 0 ? data.moy?.price?.toLocaleString("fr-FR") : "-"}
				</td>
				<td className={`bold pt-5 border-top-${color}`}>
					{data.moy?.square_meter_price > 0
						? data.moy?.square_meter_price?.toLocaleString("fr-FR")
						: "-"}
				</td>
			</tr>
		}
	</tbody>
);

export const subHeadTertiary = (
	title1 = null,
	title2 = null,
	title3 = null,
	title4 = null,
) => (
	<tr>
		<td colSpan="2" />
		<td className="sub-head">{title1}</td>
		<td className="sub-head">{title2}</td>
		<td className="sub-head">{title3}</td>
		<td className="sub-head">{title4}</td>
	</tr>
);

export const col5TableTertiary = (
	type,
	data,
	color = "light-blue",
	location = false,
) => {
	// Définition des catégories d'aire en fonction du type de local
	const areaCategories =
		type === "commercial_locals"
			? ["0-100", "100-200", "200-500", "+500"]
			: ["0-500", "500-1500", "1500-3000", "+3000"];

	return (
		<tbody className="detailed-lots-table">
			{subHeadTertiary("Nbre", "SU", "€/m²", location ? "HC HT" : "HH")}
			{areaCategories.map((area) => {
				const rowData = data?.[area] || {};

				return (
					<tr key={area}>
						<td className="w-21 no-bold" colSpan="2">
							{`${area.replace("-", " - ").replace("+", " + de ")} m²`}
						</td>
						<td className="w-19 no-bold">{rowData.number || "-"}</td>
						<td className="w-20 no-bold">
							{rowData.area ? `${rowData.area} m²` : "-"}
						</td>
						<td className="w-20 no-bold">
							{rowData.sqm_price
								? `${rowData.sqm_price.toLocaleString("fr-FR")}`
								: "-"}
						</td>
						<td className="w-20 no-bold">
							{rowData.price
								? `${Math.round(rowData.price / 1000).toLocaleString("fr-FR")} K€`
								: "-"}
						</td>
					</tr>
				);
			})}

			<tr>
				<td className={`bold border-top-${color} pt-5`} colSpan="2">
					Total
				</td>
				<td className={`bold pt-5 border-top-${color}`}>
					{data?.Total?.number || "-"}
				</td>
				<td className={`bold pt-5 border-top-${color}`}>
					{data?.Total?.area ? `${data.Total.area} m²` : "-"}
				</td>
				<td className={`bold pt-5 border-top-${color}`}>
					{data?.Total?.sqm_price
						? `${data.Total.sqm_price.toLocaleString("fr-FR")}`
						: "-"}
				</td>
				<td className={`bold pt-5 border-top-${color}`}>
					{data?.Total?.price
						? `${Math.round(data.Total.price / 1000).toLocaleString("fr-FR")} K€`
						: "-"}
				</td>
			</tr>
		</tbody>
	);
};

export const renderTr = (title, dataset, subdata, span = "", func = null) => (
	<tr>
		<td>{title}</td>
		{dataset.length > 0 ? (
			dataset.map((zi, index) => {
				const value = func ? func(zi[subdata]) : zi[subdata];
				return zi[subdata] ? (
					<td
						key={`${subdata}-${
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							index
						}`}
					>
						{value}
						<span>{` ${span}`}</span>
					</td>
				) : (
					<td
						key={`${subdata}-${
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							index
						}`}
						className="no-bold"
					>
						-
					</td>
				);
			})
		) : (
			<td className="no-bold">-</td>
		)}
	</tr>
);

export const renderTd = (data, span = "", classn = "") => {
	if (data) {
		return (
			<td className={classn}>
				{data}
				<span>{` ${span}`}</span>
			</td>
		);
	}
	return <td>-</td>;
};

const rating = (index, full) => {
	let length = index;
	let classes = "full";
	if (length === 1 || length === 2) {
		classes = "full full__red";
	}
	if (length === 3) {
		classes = "full full__yellow";
	}
	if (length === 4 || length === 5) {
		classes = "full full__green";
	}
	if (full !== "full") {
		length = 5 - index;
		classes = "empty";
	}

	return Array.from({ length: length }, (x, i) => (
		<div
			key={`confidenceIndex-full-${
				// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
				i
			}`}
			className={classes}
		/>
	));
};

export const camelize = (str) => {
	if (!str) return "-";
	return str
		.split(/[-_\s]+/)
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(" ");
};

export const Rating = ({ index, pertinence = true }) => (
	<div className="rating">
		{rating(index, "full")}
		{rating(index, "empty")}
		{pertinence && <span className="relevance comment">Pertinence</span>}
	</div>
);

export const cutAddress = (address) => {
	if (!address) return "-";
	return address.replace(/,/g, "").replace(/(\d{5})/, "\n$1");
};

export const formatAddress = (address) => {
	if (!address) return "-";

	try {
		address = address.replace(/,/g, "");
		const postalCodeRegex = /(\d{5})/;
		const matches = address.match(postalCodeRegex);
		const postalCodeIndex = matches ? matches.index : -1;
		const firstPart =
			postalCodeIndex !== -1
				? address.slice(0, postalCodeIndex).trim()
				: address.trim();
		const secondPart =
			postalCodeIndex !== -1 ? address.slice(postalCodeIndex).trim() : "";
		return (
			<>
				<div className="single-line-ellipsis">{firstPart}</div>
				<div className="single-line-ellipsis">{secondPart}</div>
			</>
		);
	} catch (error) {
		return address;
	}
};

export const cityWithoutDVF = (inseeCode) => {
	const prefix = String(inseeCode).slice(0, 2);
	return ["57", "67", "68"].includes(prefix);
};

export const lineBreak = (str) => {
	try {
		if (!str) return "-";
		return str.length > 30
			? `${str.slice(0, 30)}\n${str.chars.slice(30)}`
			: str;
	} catch (error) {
		return str;
	}
};

export const defineBounds = (markers) => {
	if (!markers || markers.length === 0) {
		return null;
	}

	const points = markers.map((marker) => [marker.lon, marker.lat]);
	const longitudes = points.map((point) => point[0]);
	const latitudes = points.map((point) => point[1]);
	const minLng = Math.min(...longitudes);
	const minLat = Math.min(...latitudes);
	const maxLng = Math.max(...longitudes);
	const maxLat = Math.max(...latitudes);
	return new mapboxgl.LngLatBounds([minLng, minLat], [maxLng, maxLat]);
};

export const adjustSuperposition = (markers, bounds, pcBuffer = false) => {
	if (!bounds) {
		return [];
	}
	const width = Math.abs(bounds._ne.lng - bounds._sw.lng);
	const height = Math.abs(bounds._ne.lat - bounds._sw.lat);
	const latitudeAdjustment = height * (pcBuffer ? 0.09 : 0.15); // en % la hauteur de la bbox
	const longitudeAdjustment = width * 0.15; // en % de la largeur de la bbox

	for (let i = 0; i < markers.length - 1; i++) {
		for (let j = i + 1; j < markers.length; j++) {
			if (
				Math.abs(markers[i].lon - markers[j].lon) < longitudeAdjustment &&
				Math.abs(markers[i].lat - markers[j].lat) < latitudeAdjustment
			) {
				markers[i].lat - markers[j].lat > 0
					? // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
						(markers[j].lat -= latitudeAdjustment)
					: // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
						(markers[j].lat += latitudeAdjustment);
			}
		}
	}

	return markers;
};

export const ErrorMarker = (
	// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
	<svg
		width="30"
		height="30"
		viewBox="0 0 54 54"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="54" height="54" rx="27" fill="#FCE6E6" />
		<path
			d="M27.0002 34.083C27.4016 34.083 27.738 33.9472 28.0095 33.6757C28.2811 33.4042 28.4168 33.0677 28.4168 32.6663C28.4168 32.265 28.2811 31.9285 28.0095 31.657C27.738 31.3854 27.4016 31.2497 27.0002 31.2497C26.5988 31.2497 26.2623 31.3854 25.9908 31.657C25.7193 31.9285 25.5835 32.265 25.5835 32.6663C25.5835 33.0677 25.7193 33.4042 25.9908 33.6757C26.2623 33.9472 26.5988 34.083 27.0002 34.083ZM25.5835 28.4163H28.4168V19.9163H25.5835V28.4163ZM27.0002 41.1663C25.0404 41.1663 23.1988 40.7945 21.4752 40.0507C19.7516 39.307 18.2522 38.2976 16.9772 37.0226C15.7022 35.7476 14.6929 34.2483 13.9491 32.5247C13.2054 30.8011 12.8335 28.9594 12.8335 26.9997C12.8335 25.04 13.2054 23.1983 13.9491 21.4747C14.6929 19.7511 15.7022 18.2518 16.9772 16.9768C18.2522 15.7018 19.7516 14.6924 21.4752 13.9486C23.1988 13.2049 25.0404 12.833 27.0002 12.833C28.9599 12.833 30.8016 13.2049 32.5252 13.9486C34.2488 14.6924 35.7481 15.7018 37.0231 16.9768C38.2981 18.2518 39.3075 19.7511 40.0512 21.4747C40.795 23.1983 41.1668 25.04 41.1668 26.9997C41.1668 28.9594 40.795 30.8011 40.0512 32.5247C39.3075 34.2483 38.2981 35.7476 37.0231 37.0226C35.7481 38.2976 34.2488 39.307 32.5252 40.0507C30.8016 40.7945 28.9599 41.1663 27.0002 41.1663ZM27.0002 38.333C30.1641 38.333 32.8439 37.2351 35.0397 35.0393C37.2356 32.8434 38.3335 30.1636 38.3335 26.9997C38.3335 23.8358 37.2356 21.1559 35.0397 18.9601C32.8439 16.7643 30.1641 15.6663 27.0002 15.6663C23.8363 15.6663 21.1564 16.7643 18.9606 18.9601C16.7647 21.1559 15.6668 23.8358 15.6668 26.9997C15.6668 30.1636 16.7647 32.8434 18.9606 35.0393C21.1564 37.2351 23.8363 38.333 27.0002 38.333Z"
			fill="#D95D57"
		/>
	</svg>
);

export const Asterich = styled.div`
  color: ${(props) => props.theme.lightGrey};
  line-height: 12px;
  display: flex;
  gap: 4px;
  font-size: 10px;
  font-style: italic;
  flex-grow: ${(props) => (props.flexGrow ? "1" : "0")};
  margin-top: ${(props) => (props.flexGrow ? "0" : "9px")};
  align-items: flex-end;
  `;

const StyledWrapper = styled.div`
  .bold {
    font-weight: 600 !important;
  }

  .no-bold {
    font-weight: 400;
  }

  .italic {
    font-style: italic;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mb-2 {
    margin-bottom: 2px;
  }

  .mb-3 {
    margin-bottom: 3px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .mt-100 {
    margin-top: 100px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .ml-3 {
    margin-left: 3px;
  }

  .pt-5 {
    padding-top: 5px;
  }

  .p-15 {
    padding: 15px;
  }

  .mr-15 {
    margin-right: 15px;
  }

  .mr-3 {
    margin-right: 3px;
  }

  .d-flex {
    display: flex;
  }

  .a-center {
    align-items: center;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .flex-column {
    flex-direction: column;
  }

  .gap-4 {
    gap: 4px;
  }

  .gap-12 {
    gap: 12px;
  }

  .w-100 {
    width: 130px;
  }

  .w-full {
    width: 100%;
  }

  .comment {
    font-size: 10px;
    font-style: italic;
    line-height: 9px;
  }

  .col-2 {
    td:nth-of-type(1) {
      width: 50%;
    }
  }

  .col-3 {
    td:nth-of-type(1) {
      width: 60%;
    }
    td:nth-of-type(2) {
      width: 20%;
    }
  }

  .col-5 {
    td {
      width: 20%;
    }
  }

  .title-2-lines {
    th {
      padding-bottom: 5px;
    }
    span {
      display: block;
      margin-top: -6px;
    }
  }

  .align-start {
    align-items: flex-start !important;
  }

  .align-center {
    align-items: center !important;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-center {
    justify-content: center;
  }

  .h-100 {
    height: 100%;
  }

  .height-200px {
    height: 200px;
  }

  .height-240px {
    height: 238px;
  }

  .height-280px {
    height: 280px;
  }

  .height-250px {
    height: 250px;
  }

  .pdf-content-inside {
    height: 920px;
  }

  .w-49 {
    width: 49% !important;
  }

  .w-50 {
    width: 50%;
  }

  .bg-light-red-legend {
    background-color: #FFAAA5;
  }

  .bg-light-red {
    background-color: ${(props) => props.theme.lightRedBackground};
  }

  .bg-light-white {
    background-color: ${(props) => props.theme.lightRedBackground};
  }

  .bg-light-gray {
    background-color: ${(props) => props.theme.tertaryGrey};
  }

  .bg-light-blue {
    background-color: ${(props) => props.theme.lightBlueBackground};
  }

  .bg-candy-primary {
    background-color: ${(props) => props.theme.candyPrimary};
  }

  .bg-candy-secondary {
    background-color: ${(props) => props.theme.candySecondary};
  }

  .font-size-12px {
    font-size: 12px
  }

  .font-size-14px {
    font-size: 14px
  }

  .font-size-12px {
    font-size: 12px
  }

  .font-size-7px {
    font-size: 7px
  }

  .font-size-9px {
    font-size: 9px
  }

  .color-light-grey {
    color: ${(props) => props.theme.lightGrey};
  }

  .color-candy-primary {
    color: ${(props) => props.theme.candyPrimary};
  }

  // MAIN COMPONENTS //

  .pdf-content {
    padding: 24px;
    font-family: 'Gilroy';
    color: ${(props) => props.theme.candyPrimary};
    font-weight: 400;
    display: flex;
    flex-direction: column;
  }

  header {
    margin-bottom: 14px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    img {
      width: 12px;
      height: 12px;
    }
    .theme {
      margin-left: 12px;
      padding: 2px 8px;
      font-weight: 500;
      font-size: 15px;
      border-radius: 3px;
      border: 1px solid #31325F;
      line-height: 17px;
    }

    .header-tertiary {
      height: 42px;
    }
  
    .study-area {
      border-radius: 3px;
      font-weight: 500;
      line-height: 9.54px;
      padding: 6px 16px;
      color: #31325F;
      font-size: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .sub-header {
    font-size: 18px;
    font-weight: 600;
    height: 32px;
  }

  h1 {
    font-weight: 700;
    font-size: 22px;
    margin: 0px;
    line-height: 24.18px;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    margin: 0px;
    line-height: 13.92px;
    display: flex;
  }

  footer {
    background-color: transparent;
    margin-top: 24px;
    width: 100%;
    height: fit-content;

    svg {
      height: 30px;
    }

    .white-border {
      border: 1px solid ${(props) => props.theme.lightBlueBackground};
      margin-bottom: 10px;
    }

    .footer-box {
      div:nth-of-type(2) {
        line-height: 9px;
        justify-content: space-between;
      }

      .comment {
        width: 800px;
        line-height: 12px;
      }
    }
  }

  .page-2-col {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;

    > table, > div {
      width: 48%;
    }
  }

  .page-2-col-stretch {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 12px;

    > table, > div {
      width: 48%;
    }
  }

  .asterich {
    color: ${(props) => props.theme.lightGrey};
    line-height: 12px;
    display: flex;
    gap: 4px;
    font-size: 10px;
    font-style: italic;
    flex-grow: 1;
    align-items: flex-end;
  }

  // MORE SPECIFIC CLASSES //

  .blue-background {
    background-color: ${(props) => props.theme.lightBlueBackground};
  }

  .grey-background {
    background-color: ${(props) => props.theme.tertaryGrey};
  }

  .green-background {
    background-color: ${(props) => props.theme.tertaryGreen};
  }

  .red-background {
    background-color: ${(props) => props.theme.lightRedBackground};
  }

  .yellow-background {
    background-color: ${(props) => props.theme.lightYellowBackground} !important;
  }

  .white-background {
    background-color: transparent !important;
    border: 1px solid ${(props) => props.theme.lightBlueBackground} !important;

    thead::after {
      border-bottom: 2px solid ${(props) => props.theme.lightBlueBackground} !important;
    }
  }

  .border-top-light-blue {
    border-top: 2px solid ${(props) => props.theme.lightBlueBackground};
  }

  .border-top-white {
    border-top: 2px solid white;
  }

  .border-radius-3 {
    border-radius: 3px;
  }


  .location {
    height: 89px;
    td {
      font-size: 28px;
    }
  }

  .location-detailed-program {
    font-size: 14px;
    padding: 10px 12px 10px 12px;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .green-background {
    background-color: ${(props) => props.theme.tertaryGreen};
  }

  .width-240 {
    td:nth-of-type(1) {
      width: 240px;
    }
  }

  .stickers {
    display: flex;
    gap: 10px;

    div {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.lightYellowBackground};
      border-radius: 20px;
      padding: 2px 10px 2px 10px;
      font-size: 12px;
    }
  }

  .rating {
    display: flex;
    gap: 2px;
    align-items: center;

    .full {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      &.full {
        &__yellow { background-color: ${(props) => props.theme.candyYellow} }
        &__green { background-color: ${(props) => props.theme.candyGreen} }
        &__red { background-color: ${(props) => props.theme.candySecondary} }
      }
    }

    .empty {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: white;
    }
  }

  #full-map {
    max-width: calc(100%);
  }

  #half-map {
    max-width: calc(100%);
  }

  .programs-legend {
    font-size: 10px;
    height: 9px;
  }

  .colored-bullet {
    display: inline-block;
    width: 6px;
    height: 3px;
    border-radius: 30%;
    margin-right: 4px;
    margin-bottom: 1px;
  }
  
  .tertiary-bullet {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.candyPrimary};
    border: 1px solid ${(props) => props.theme.candyPrimary};
    margin-right: 4px;
  }

  .tertiary-bullet-empty {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid ${(props) => props.theme.candyPrimary};
    margin-right: 4px;
  }

  #side-tables {
    display: flex;

    table {
      width: 50%;
    }

    thead:first-of-type {
      height: 44px;
    }

    thead:last-of-type {
      &::after {
        border-bottom: none;
      }
    }
    thead:last-of-type {
      border: none;
    }

    tbody {
      td {
        width: 20%;
      }
    }

    // table:nth-of-type(2) > tbody {
    //   border-left: 1px solid white;
    // }
  }
  #side-tables-office {
    display: flex;

    table {
      width: 50%;
    }

    thead:first-of-type {
      height: 44px;
    }

    thead:last-of-type {
      &::after {
        border-bottom: none;
      }
    }
    thead:last-of-type {
      border: none;
    }

    // table:nth-of-type(2) > tbody {
    //   border-left: 1px solid white;
    // }
  }

  .price-evaluation {
    padding: 8;
    text-align: center;
    border-radius: 3px;
  }

  .canvas-border {
    border: 1px solid ${(props) => props.theme.lightBlueBackground};
    padding: 0px 8px;
    border-radius: 3px;
  }

  #meanPriceEvolution {
    padding-top: 3px;
    tbody > tr:nth-of-type(2), tr:nth-of-type(3) > td {
      background-color: ${(props) => props.theme.lightBlueBackground};
    }
  }

  .detailed-lots-table {
    table {
      border-collapse: collapse;
      border: none;
      thead {
        font-size: 10px;
        color: ${(props) => props.theme.lightGrey};
        text-align: left;
        position: relative;
        line-height: 24px;

        th {
          font-weight: 400;
        }

      }
      thead::after {
        content: none;
      }
    }
  }

  .covers-container {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    background-color: white;
  }

  .cover {
    background-color: ${(props) => props.theme.tertaryGrey};
    border-radius: 3px;
    height: 134px;
    width: 174px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }
  }

  .marker-index {
    font-weight: 600;
    font-family: 'Gilroy';
    padding-top: 1.5px;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .marker-index-program-available {
    width: 20px;
    background-color: ${(props) => props.theme.candySecondary};
    color: white;
    border: 1px solid white;
  }

  .marker-index-program-sold {
    width: 20px;
    background-color: ${(props) => props.theme.lightRedBackground};
    color: ${(props) => props.theme.candySecondary};
    border: 1px solid ${(props) => props.theme.candySecondary};
  }

  .marker-index-vefa {
    width: 20px;
    background-color: ${(props) => props.theme.candyPrimary};
    color: white;
    border: 1px solid white;
  }

  .marker-index-office-ongoing {
    width: 20px;
    background-color: ${(props) => props.theme.candyPrimary};
    color: white;
    border: 1px solid white;
  }
  .marker-index-office-sold {
    width: 20px;
    background-color: white;
    color: ${(props) => props.theme.candyPrimary};
    border: 1px solid ${(props) => props.theme.candyPrimary};
  }

  .marker-content {
    background-color: white;
    color: ${(props) => props.theme.candyPrimary};
    padding: 0px 3px 0px 3px;
    border-radius: 3px;
    margin-left: 3px
  }

  .marker-content-bp {
    div {
      height: 14px;
    }
    div:last-of-type {
      margin-bottom: 4px;
    }
  }

  .google-pic {
    img {
      width: 100%;
      height: auto;
    }
  }

  #near-programs-map {
    width: 100%;
    height: 450px;
  }

  #sales-prices-by-type {
    span {
      display: block;
      margin-top: -6px;
    }
  }

  .mapbox-improve-map {
    display: none;
  }

  .mapboxgl-ctrl-attrib-button {
    display: none;
  }

  .mapboxgl-ctrl-attrib-inner {
    font-size: 10px;
  }

  .mapboxgl-ctrl-logo {
    display: none !important;
  }
`;

export const BaseTable = styled.table`
  width: 100%;
  padding: 10px 12px 10px 12px;
  background-color: ${(props) => props.theme.lightBlueBackground};
  border-radius: 3px;

  thead {
    font-size: 14px;
    text-align: left;
    position: relative;
    line-height: 24px;
  }

  thead::after {
    content: '';
    position: absolute;
    left: 0%;
    bottom: 0;
    width: 100%;
    border-bottom: 2px solid white;
  }

  tbody {
    tr {
      line-height: 16px;
    }
    .absent {
      opacity: 0.5;
      svg {
        opacity: 0.5;
      }
    }
  }

  td {
    font-size: 12px;
    font-weight: 600;

    &:nth-of-type(1) {
      font-weight: 400;
    }
  }

  .relevance {
    color: ${(props) => props.theme.lightGrey};
  }

  .empty {
    height: 12px;
  }

  .bullet::before {
    content: '•';
    margin: 0px 5px;
  }

  .sub-head {
    color: ${(props) => props.theme.lightGrey};
    font-size: 10px;
    font-weight: 400 ;
  }

  .mw-90 {
    max-width: 95%;
  }
`;

export const SerialDataTable = styled.table`
  width: 100%;
  padding: 10px 0px 10px 0px;
  margin-bottom: 10px;
  line-height: 14px;
  background-color: white;
  border-collapse: separate;
  border-spacing: 0 6px;

  thead {
    font-size: 10px;
    color: ${(props) => props.theme.lightGrey};
    text-align: left;
    position: relative;
    line-height: 22px;

    th {
      font-weight: 400;
      align-content: end;
    }
  }

  thead::after {
    content: none;
  }

  tbody {
    td {
      font-size: 12px;
      font-weight: 400;
    }

    td:last-child {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }
    td:first-child {x
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
  }

  .height-40px {
    height: 40px;
  }

  .td-container {
    flex-direction: column;
    justify-content: center;
    height: 40px;
  }

  .single-line-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: normal;
  }

  .multi-line-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: normal;
  }

  .td-address-near-programs {
    white-space: pre-line;
  }
  

  .w-5 {
    width: 5%;
  }
  
  .w-6 {
    width: 5%;
  }

  .w-7 {
    width: 7%;
  }

  .w-10 {
    width: 10%;
  }

  .w-11 {
    width: 11%;
  }

  .w-13 {
    width: 13%;
  }

  .w-15 {
    width: 15%;
  }

  .w-19 {
    width: 19%;
  }

  .w-20 {
    width: 20%;
  }
    
  .w-21 {
    width: 21%;
  }

  .w-25 {
    width: 25%;
  }

  .w-28 {
    width: 28%;
  }

  .w-40 {
    width: 40%;
  }

  .pl-5 {
    padding-left: 5px;
  }

  .pr-5 {
    padding-right: 5px;
  }

  .td-bp-marker {
    line-height: auto !important;
    padding-right: 5px;
  }
  
.office-cover {
    padding: 2px;
    width: 55px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.office-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}
`;

export const CircleProgram = styled.div`
  padding-top: 1.5px;
  font-family: 'Gilroy';
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;

  ${(props) =>
		props.className.includes("index") &&
		css`
    margin-left: 10px;
  `}

  ${(props) =>
		props.className.includes("program-finished") &&
		css`
    width: 20px;
    height: 20px;
    border: 1px solid ${props.theme.candySecondary};
    background-color: ${props.theme.lightRedBackground};
    color: ${props.theme.candySecondary};
  `}

  ${(props) =>
		props.className.includes("program-ongoing") &&
		css`
    width: 20px;
    height: 20px;
    border: 1px solid ${props.theme.candySecondary};
    background-color: ${props.theme.candySecondary};
    color: white;
  `}

  ${(props) =>
		props.className.includes("vefa-programs") &&
		css`
    width: 20px;
    height: 20px;
    border: 1px solid ${props.theme.candyPrimary};
    background-color: ${props.theme.candyPrimary};
    color: white;
  `}

  ${(props) =>
		props.className.includes("office-ongoing") &&
		css`
    width: 20px;
    height: 20px;
    border: 1px solid ${props.theme.candyPrimary};
    background-color: ${props.theme.candyPrimary};
    color: white;
  `}

  ${(props) =>
		props.className.includes("office-finished") &&
		css`
    width: 20px;
    height: 20px;
    border: 1px solid ${props.theme.candyPrimary};
    background-color: white;
    color: ${props.theme.candyPrimary};
  `}

  ${(props) =>
		props.className.includes("bp") &&
		css`
    width: 24px;
    height: 24px;
    border: 2px solid white;
    background-color: ${props.theme.candyYellow};
    color: ${props.theme.candyPrimary};
  `}
`;

export const PlotMap = styled.div`
  width: 100%;
  height: 400px;
  background-image: url("${(props) => props.url}");
  background-size: cover;
  background-position: center;
`;
export const WarningMissingData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 53px;
  font-size: 14px;
  margin: 20px 0 0 0;
  background-color: ${(props) => (props.yellow ? props.theme.lightYellowBackground : props.theme.lightBlueBackground)};

  p {
    text-align: center;
    line-height: 14.32px;
    color: #999999;

    b {
      font-weight: 600;
    }
  }
`;
export const WarningMissingDataTertiary = styled.div`
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 10px;
  font-size: 14px;
  gap: 10px;
  border-radius: 3px;
  margin-bottom: 12px;
  background-color: ${(props) => props.theme.lightRedBackground};

  svg {
    width: 30px;
    height: 30px;
    width: 10%;
  }

  p {
    text-align: center;
    line-height: 14.32px;
    color: #999999;
    font-weight: 600
  }

  span {
    font-weight: 500;
  }
`;
