import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import {
	tertiarySellsDistribution,
	tertiaryPriceDistribution,
} from "../ChartDatas";

import {
	BaseTable,
	doubleHead,
	col5TableTertiary,
	WarningMissingDataTertiary,
	ErrorMarker,
} from "../Shared";
const MarketOverview = ({ plot, type, localType, studyType, buffer }) => {
	const officeData = plot?.offices_data_aggregation?.[type] || {};
	const data_sale = officeData?.sale || {};
	const data_rent = officeData?.rent || {};
	const sellsCanvasRef = useRef(null);
	const rentsCanvasRef = useRef(null);
	const sellsPriceCanvasRef = useRef(null);
	const rentsPriceCanvasRef = useRef(null);

	const sellsChartRef = useRef(null);
	const rentsChartRef = useRef(null);
	const sellsPriceChartRef = useRef(null);
	const rentsPriceChartRef = useRef(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		// Vente - Distribution
		if (sellsCanvasRef.current) {
			const ctx = sellsCanvasRef.current.getContext("2d");
			if (sellsChartRef.current) sellsChartRef.current.destroy();

			const chartData =
				officeData?.sale?.Total?.number >= 20
					? plot.office_availability_count[type]?.sale
					: {}; // Données vides si pas assez

			sellsChartRef.current = new Chart(
				ctx,
				tertiarySellsDistribution(type, chartData),
			);
		}

		// Location - Distribution
		if (rentsCanvasRef.current) {
			const ctx = rentsCanvasRef.current.getContext("2d");
			if (rentsChartRef.current) rentsChartRef.current.destroy();

			const chartData =
				officeData?.rent?.Total?.number >= 20
					? plot.office_availability_count[type]?.rent
					: {}; // Données vides si pas assez

			rentsChartRef.current = new Chart(
				ctx,
				tertiarySellsDistribution(type, chartData, true),
			);
		}

		// Vente - Prix/m²
		if (sellsPriceCanvasRef.current) {
			const ctx = sellsPriceCanvasRef.current.getContext("2d");
			if (sellsPriceChartRef.current) sellsPriceChartRef.current.destroy();

			const chartData =
				officeData?.sale?.Total?.number >= 20
					? plot.office_sqm_stats[type]?.sale
					: {};

			sellsPriceChartRef.current = new Chart(
				ctx,
				tertiaryPriceDistribution(type, chartData),
			);
		}

		// Location - Prix/m²
		if (rentsPriceCanvasRef.current) {
			const ctx = rentsPriceCanvasRef.current.getContext("2d");
			if (rentsPriceChartRef.current) rentsPriceChartRef.current.destroy();

			const chartData =
				officeData?.rent?.Total?.number >= 20
					? plot.office_sqm_stats[type]?.rent
					: {};

			rentsPriceChartRef.current = new Chart(
				ctx,
				tertiaryPriceDistribution(type, chartData, true),
			);
		}

		return () => {
			if (sellsChartRef.current) sellsChartRef.current.destroy();
			if (rentsChartRef.current) rentsChartRef.current.destroy();
			if (sellsPriceChartRef.current) sellsPriceChartRef.current.destroy();
			if (rentsPriceChartRef.current) rentsPriceChartRef.current.destroy();
		};
	}, [plot, type]);

	return (
		<div className="pdf-content-inside">
			<div id="side-tables-office" className="mb-12">
				<BaseTable>
					<thead id="sales-prices-by-type">
						<tr>
							<th colSpan={5}>
								Annonces enregistrées par catégorie
								<br />
								<span className="font-size-12px">
									Au cours des 12 derniers mois
								</span>
							</th>
						</tr>
					</thead>
					<thead>
						<tr>
							<th colSpan="5" className="font-size-12px">
								À la vente
							</th>
						</tr>
					</thead>
					{col5TableTertiary(type, data_sale)}
				</BaseTable>

				<BaseTable>
					{doubleHead("", "")}
					<thead>
						<tr>
							<th colSpan="5" className="font-size-12px">
								À la location
							</th>
						</tr>
					</thead>
					{col5TableTertiary(type, data_rent, "light-blue", true)}
				</BaseTable>
			</div>
			{(officeData?.sale?.Total?.number < 20 ||
				officeData?.rent?.Total?.number < 20) && (
				<WarningMissingDataTertiary>
					<p>
						Le volume de données de marché{" "}
						{officeData?.sale?.Total?.number < 20 &&
						officeData?.rent?.Total?.number < 20
							? "à la vente et à la location"
							: officeData?.sale?.Total?.number < 20
								? "à la vente"
								: "locatif"}{" "}
						est trop faible pour vous fournir des données consolidées
						pertinentes
						<br />
						<span>
							{studyType === "city"
								? `sur la commune de ${buffer}`
								: `dans un rayon de ${buffer / 1000} km.`}
						</span>
					</p>
				</WarningMissingDataTertiary>
			)}
			<div className="page-2-col">
				<div className="height-280px w-49">
					<canvas className="canvas-border" ref={sellsPriceCanvasRef} />
				</div>
				<div className="height-280px w-49">
					<canvas className="canvas-border" ref={rentsPriceCanvasRef} />
				</div>
			</div>
			<div className="mb-30" />
			<div className="page-2-col">
				<div className="height-280px w-49">
					<canvas className="canvas-border" ref={sellsCanvasRef} />
				</div>
				<div className="height-280px w-49">
					<canvas className="canvas-border" ref={rentsCanvasRef} />
				</div>
			</div>
		</div>
	);
};

export default MarketOverview;
