import React from 'react'
import styled from 'styled-components'

const CalendlyWidget = () => {
  return (
    <div>
      <div className="contact-row">
        <p><b>Je souhaite prendre un <strong>rendez-vous téléphonique</strong> avec City&You</b></p>
      </div>
      <CalendlyWrapper className="calendly-inline-widget">
        <iframe
          src="https://calendly.com/sinclair-baglin-cityandyou/demande-de-rendez-vous"
          width="100%"
          height="100%"
          frameBorder="0"
          title="Calendly Widget"
        />
      </CalendlyWrapper>
    </div>
  )
}

const CalendlyWrapper = styled.div`
  minWidth: '320px'
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 1000px;
  }
  @media (min-width: 769px) {
    height: 1100px;
  }
`

export default CalendlyWidget